import React, { useCallback, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components'
import useStore from '../../core/Store';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs'
import Cookies from 'js-cookie';
import Login, { loginUser} from './Login';
import axios from 'axios';
import { extend } from '@react-three/fiber';

export default function SingUp(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [token, setToken] = useState();
    const [username, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [password, setPassword] = useState('');
    // SALT should be created ONE TIME upon sign up
    const salt = process.env.REACT_APP_SALT
    const authorizedCallback = useCallback((res) => { 
        props.authorizedCallback(res); 
      }, [props]); 
    const handleErrorCallback = useCallback((res) => { 
      props.handleErrorCallback(res); 
    }, [props]); 
    const [ipAddress, setIP] = useState()
    axios.get("https://api.ipify.org?format=json")
    .then(data => {
        setIP(data.data.ip)
    });
    const handleSubmit = async e => {
        e.preventDefault();
        const hashedPassword = bcrypt.hashSync(password) // hash created previously created upon sign up
        const token = bcrypt.hashSync(password) // hash created previously created upon sign up
        useStore.setState(state => ({
          username: username,
          password: password,
          token: token,
        }));
     
        const res = await SignUpUser({
          username: username,
          password: hashedPassword,
          email: email,
          firstname: firstname,
          lastname: lastname,
          source: "real-estates-world-web",
          token: token,
          ip: ipAddress,
          money: 100000
        });

        let message = res && res.error ? res.error : 'Something went wrong, check your credentials, then try again'
        setDisabled(true)
          if (res && !res.error) {
           createUserLocal(res);
          const loginRes = await loginUser({
            username: username,
            password: password
          });

          authorizedCallback(loginRes)
        }else{
          setDisabled(false)
          setPassword('')
          handleErrorCallback(message)
        }
        
      }

  return(
      <SingUpCss>
        <form onSubmit={handleSubmit}>
        <label>
          <input required type="email" onChange={e => setEmail(e.target.value)} placeholder="Email" disabled={disabled} />
        </label>
        <label>
          <input required type="text" onChange={e => setUserName(e.target.value)} placeholder="Username" disabled={disabled} />
        </label>
        <label>
          <input required type="text" onChange={e => setFirstName(e.target.value)} placeholder="First Name" disabled={disabled} />
        </label>
        <label>
          <input required type="text" onChange={e => setLastName(e.target.value)} placeholder="Last Name" disabled={disabled} />
        </label>
        <label>
          <input required type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" disabled={disabled} value={password} />
        </label>
        <div>
          <button type="submit" disabled={disabled} >Submit</button>
        </div>
      </form>
    </SingUpCss>
  )
}


async function SignUpUser(credentials) {
   
      let options = {
        url: process.env.REACT_APP_AUTH+"/signup",
        method: 'POST',
        headers: {
            'cache-control': 'no-cache',
            'content-type': 'application/json',
            accept: 'application/json',
        },
        data: credentials
      };

    let results = await axios(options)
      .then(response => {
        if (response.data.token) {
          //  now create in local
          
          useStore.setState(state => ({
            userId: parseInt(response.data.id),
            token: response.data.token,
            username: response.data.username,
            money: parseInt(response.data.money),
           }))
           return response.data;
        }else{
          return response.data;
        }
        
      }).catch(err => {
        console.log(err.message)
      })
     
     return results;
   }
   
  Login.propTypes = {
    authorizedCallback: PropTypes.func.isRequired
  };
function createUserLocal(response){
   // Now update the local storage of users for money and lands
            // Update sessions for the latest results
            let dataToSend = {
              userId: parseInt(response.id), 
              status: 1, 
              email: response.data.email, 
              username: response.data.username, 
              firstname: response.data.firstname, 
              lastname: response.data.lastname,  
              token: response.data.token, 
              logstatus: 1,
              loggedintime: Date.now(),
              updated_at: Date.now()
            }
            let optionsLocal = {
              url: process.env.REACT_APP_AUTH + `/users?userId=${parseInt(response.id)}`,
              method: 'POST',
              headers: {
                  'cache-control': 'no-cache',
                  'content-type': 'application/json',
                  accept: 'application/json',
              },
              data: JSON.stringify(dataToSend)
            };
            
         return axios(optionsLocal).then(res => {
            }).catch(err => {
            console.log(err.message)
          })

 }

const base = css`
font-family: 'Teko', sans-serif;
position: absolute;
text-transform: uppercase;
font-weight: 900;
font-variant-numeric: slashed-zero tabular-nums;
line-height: 1em;
pointer-events: all;
color: white;
overflow: auto;
z-index: 99;
background: rgb(0, 0 ,0 ,0.2);
& > form {
    color: white;
    background: rgb(0, 0 ,0 ,0.2);
}
`
const SingUpCss = styled.div`
${base}
top: 50vh;
left: 10vw;
max-height: 40vh;
transform: skew(-5deg, -5deg);
pointer-events: all;
cursor: pointer;
text-align:left;
& > a {
  color: white;
  text-decoration: none;
  
}
& > div {
  color: white;
  text-decoration: none;
  
}
& > h1 {
  margin: 0;
  font-size: 10em;
  line-height: 1em;
}
& > h2 {
  margin: 0;
  font-size: 4em;
  line-height: 1em;
}
@media only screen and (max-width: 1200px) {
  top: 40vh;
  left: 1vw;
  font-size: 10px;
  & > input {
    margin: 0;
    padding: 3px !important;
  }
  & > input:label {
    margin: 0;
    padding: 3px !important;
  }
  & > h1 {
    font-size: 12px !important;
  }
  & > h2 {
    font-size: 10px !important;
  }
}

`