import { OrbitControls } from '@react-three/drei'
import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import { extend, useFrame, useThree } from 'react-three-fiber'
import * as THREE from 'three'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls'
import CameraControls from 'camera-controls'

extend({ MapControls })



function Controls(props) {
  const camera = useThree((state) => state.camera)
  const gl = useThree((state) => state.gl)
  const controls = useRef() 
  let distance = [];
  if (props.orbit) {
    distance = [camera.position.x, camera.position.y, camera.position.z];
  }
  useFrame(({clock}) => {
    if (props.orbit) {
    controls.current.update()
    let cameraNow = [camera.position.x, camera.position.y, camera.position.z];
    var difference = distance.filter(x => cameraNow.indexOf(x) === -1);
      if (difference.length > 0 && props.orbit) {
        distance = [camera.position.x, camera.position.y, camera.position.z];
        props.zoomChanged(distance)
      }
    }
  })
 
  
  // const zoomChanged = useCallback(
  //   () => {
  //     props.zoomChanged(distance)
  //   },
  //   [distance],
  // )
  if (props.orbit) {
   
     return (
     <OrbitControls 
            ref={controls}
            enableZoom={true} 
            enablePan={false} 
            maxZoom={3}
            enableRotate={true} 
            zoomSpeed={2.5} 
            panSpeed={0.5} 
            rotateSpeed={0.4} 
            args={[camera, gl.domElement]}
            enableDamping={false}
            dampingFactor={0.05}
            minDistance={props.minDistance} // 100 for city
            maxDistance={props.maxDistance}
            />
        );
  }
  return (
    <mapControls
      ref={controls}
      args={[camera, gl.domElement]}
      enableDamping={false}
      dampingFactor={0.05}
      minDistance={props.minDistance} // 100 for city
      maxDistance={props.maxDistance} // 500 for city
      maxPolarAngle={Math.PI / 2}
      rotation={true}
      zoomSpeed={0.6} 
      panSpeed={0.5} 
      rotateSpeed={0.4}
      enableRotate={props.enableRotate}
      translate={100}
      // {...props}
    />
  )
}

export default Controls
