import React, { useEffect, useState } from "react";
 import "./agent.css"
 import manAgent from "../../assets/icons/man-agent.png"
import LeftBoard from "../../components/mainmenu/leftboard";
import * as bg from '../../components/audio'
import useStore from "../Store";
 

const AgentPopup = (props) => {
  const [newNote, setnewNote] = useState(0);
  const [hide, sethide] = useState(false);
  const [count, setcount] = useState(0);
  const cash = useStore((state) => state.money);
  let contents = props.content;
  const [notificationList, setnotificationList] = useState(0);
  function closeMenu(){
    sethide(false)
    setnotificationList(0)
  } 
  useEffect(() => {
      let newCount = parseInt(props.list)
      if (notificationList < newCount) {
        setnotificationList(newCount)
        setnewNote(newCount)
        if(useStore.getState().sound){
           playAudioNote();
        }
      }
      if (hide) {
        setnotificationList(0)
      }
  }, [hide, notificationList, props.list])
 
  function openMenu(){
    sethide(true)
   
  } 
  
  return (
    <div className="agent-popup-box">
      <div className="cash-box"><b>BANK: {cash.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</b></div>
      <img src={manAgent} className="agent-icon" alt="agent" onClick={openMenu}/>
      <span className="notification-icon" alt="notification">{notificationList}</span>

      {
        ((hide) ?  (<>
        <div className="agent-box">
        <span className="agent-close-icon" onClick={closeMenu}>x</span>
        {props.header}
        <ul>
          {contents.map(x => x)}
        </ul>
        <div className="agent-leftboard"> <LeftBoard visitPropOwned={props.visitPropOwned} /> </div>
        </div></>) : [])
      }
    </div>

  );
};
function playAudioNote(play = true, muted = true){
 
    if (play) {
      bg.notification.currentTime = 0
      bg.notification.volume = 0.05
      bg.notification.loop = false
      // bg.notification.muted = muted
      bg.notification.play()
    }else{
      bg.notification.pause();
    }
  
}
export default AgentPopup;