import React, { useCallback, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components'
import useStore from '../../core/Store';
import PropTypes from 'prop-types';
import bcrypt from 'bcryptjs'
import Cookies from 'js-cookie';
import axios from 'axios';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { RunAllFunctionsAfterLogin } from '../api';

export default function Login(props) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [token, setToken] = useState();
    const [disabled, setDisabled] = useState(false);
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    // SALT should be created ONE TIME upon sign up
    const salt = process.env.REACT_APP_SALT
    const authorizedCallback = useCallback((res) => { 
        props.authorizedCallback(res); 
      }, [props]); 
    const handleErrorCallback = useCallback((res) => { 
        props.handleErrorCallback(res); 
    }, [props]); 
    const handleSubmit = async e => {
        e.preventDefault();
        
        const res = await loginUser({
          username: username,
          password: password
        });
        // 👇️ clear all input values in the form
        
        setDisabled(true)
        let message = res && res.message ? res.message : 'Something went wrong, check your credentials, then try again'
        if (res && !res.message) {
            
            const loginRes = await loginUser({
              username: username,
              password: password
            });
            authorizedCallback(loginRes)
          }else{
            // setUserName('');
            setPassword('');
            setDisabled(false)
            handleErrorCallback(message)
          }
      }

  return(
      <LoginCss>
        <form onSubmit={handleSubmit}>
        <label>
          <input  disabled={disabled} required type="text"  placeholder='Username' onChange={e => setUserName(e.target.value)} value={username}/>
        </label>
        <label>
          <input  disabled={disabled} value={password} required type="password" placeholder='Password' onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button  disabled={disabled} type="submit">Submit</button>
        </div>
      </form>
    </LoginCss>
  )
}


async function loginUser(credentials) {
    // const [ipAddress, setIP] = useState()
    // axios.get("https://api.ipify.org?format=json")
    // .then(data => {
    //     console.log(data.data.ip)
    //     setIP(data.data.ip)
    // });
    let options = {
        url: process.env.REACT_APP_AUTH + "/login",
        method: 'POST',
        headers: {
            'cache-control': 'no-cache',
            'content-type': 'application/json',
            accept: 'application/json',
        },
        data: JSON.stringify(credentials)
      };
   return await axios(options)
        .then(response => {
          if (response.data.token) {
             const storeData = {
                name: response.data.firstname +" "+ response.data.lastname,
                username: response.data.username,
                email: response.data.email,
                token: response.data.token,
                money: parseFloat(response.data.money),
                userId: response.data.id
                };
            // update userstore
            useStore.setState({
                userId: storeData.userId,
                email: storeData.email,
                name: storeData.name,
                level: storeData.level || "1",
                username: storeData.username,
                token: storeData.token,
                money: parseFloat(response.data.money),
              })
            let encoded = base64_encode(JSON.stringify(storeData));
            sessionStorage.setItem('data', encoded)
            localStorage.setItem('data', encoded)
              RunAllFunctionsAfterLogin();
              return response.data;
            }else{
                return response.data;
            }
      }).catch(err => {
          console.log(err.message)
        })
     
   }

  Login.propTypes = {
    authorizedCallback: PropTypes.func.isRequired
  };
export { loginUser }

const base = css`
font-family: 'Teko', sans-serif;
position: absolute;
text-transform: uppercase;
font-weight: 900;
font-size: 1em;
font-variant-numeric: slashed-zero tabular-nums;
line-height: 1em;
pointer-events: all;
color: white;
background: rgb(0, 0 ,0 ,0.2);
& > form {
    color: white;
    background: rgb(0, 0 ,0 ,0.2);
}
`
const LoginCss = styled.div`
${base}
top: 50vh;
left: 10vw;
max-height: 10vh;
min-height: 10vh;
transform: skew(-5deg, -5deg);
pointer-events: all;
cursor: pointer;
text-align:left;
& > a {
  color: white;
  text-decoration: none;
}
& > div {
  color: white;
  text-decoration: none;
  
}
& > h1 {
  margin: 0;
  font-size: 10em;
  line-height: 1em;
}
& > h2 {
  margin: 0;
  font-size: 4em;
  line-height: 1em;
}
@media only screen and (max-width: 1200px) {
  top: 40vh;
  left: 1vw;
  font-size: 10px;
  & > h1 {
    font-size: 12px !important;
  }
  & > h2 {
    font-size: 10px !important;
  }
}

`