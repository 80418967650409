import { Html } from "@react-three/drei";
import React, { useEffect, useState } from "react";
import "./loading.css"
import Grass1 from '../assets/textures/loadingGrass.webp'
import Grass2 from '../assets/textures/loadingGrass2.webp'
import Grass3 from '../assets/textures/loadingGrass3.webp'
import Snow1 from '../assets/textures/loadingSnow1.webp'
import Snow2 from '../assets/textures/loadingSnow2.webp'
export default function GameLoader() {

  const [reply, setReply] = useState();
  const [randomImage, setRandomImage] = useState('');

  let renderImage = () => {
    const myImages = [
      { image: Grass1 },
      { image: Grass2 },
      { image: Grass3 },
      { image: Snow1 },
      { image: Snow2 },
    ];
    let randomImageIndex = Math.floor(Math.random() * Math.floor(myImages.length));
    return myImages[randomImageIndex].image;
  };

  useEffect(() => {
    setRandomImage(renderImage);
  }, [])


    return (<>
    <Html center>
      <div className="popup-loading" style={{ 
        backgroundImage: `url('${renderImage()}')`
       }}>
        <div className="loading">
           Loading...
        </div>
      </div>
      </Html>
    </>
  );
}