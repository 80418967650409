import React, { useCallback, useEffect, useState } from "react";
import styled, { css, createGlobalStyle } from 'styled-components'

export default function Notification (props)  {
    const [isMounted, setIsMounted] = useState(false);
    useEffect(() => {
        setIsMounted(true);
    }, []);
    const [message, setMessage] = useState(props.message);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setMessage(props.message)
      }, [props.message]);
      if(!isMounted){
        return (<></>)
    }
        return (<Note> {message} </Note> );
        
}

const base = css`
font-family: 'Teko', sans-serif;
position: absolute;
text-transform: uppercase;
font-weight: 900;
font-variant-numeric: slashed-zero tabular-nums;
line-height: 1em;
pointer-events: none;
color: white;
height: auto;
max-height: 10vh;
background: rgb(0, 0 ,0 ,0.2);
`

const Note = styled.div`
${base}
bottom: calc(30vh);
left: calc(45vw);
transform: skew(5deg, 5deg);
height: 150px;
width: 10vw;
color: white;
background: rgb(0, 0 ,0 ,0.2);
@media only screen and (max-width: 900px) {
    font-size: 0.3em;
    width: 50vw;
    bottom: calc(30vh);
    left: calc(30vw);
  }
`

const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    user-select: none;
  }

  #root {
    padding: 0px;
  }

  body {
    position: fixed;
    overscroll-behavior-y: none;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    color: black;
    background: white;
  }
`