import './App.css';
import React from "react";
import { StyleSheet, View } from 'react-native';
import MainMenu from './components/mainmenu/MainMenu';
import 'bootstrap/dist/css/bootstrap.css';
import useStore from './core/Store';
import { getCountries , getRegions, getSoldLands, setLands, startAccount } from './core/Helpers';

// Example
// {isOpen && <Popup
//     content={<>
//         <b>World Chat</b>
//         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//         <button>Test button</button>
//     </>}
//     handleClose={this.togglePopup}
//     />}

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainmenu: true,
      start: false,
      resume: false,
      finalScore: 0,
      level: '1',
      gameOver: false,
      leaderboard: false,
      contactus: false, 
      score: false, 
      signup: false,
      onClickControl: 0,
      loading: true,
      mComplete: false,
      timestamp: 'no timestamp yet',
      soldLands: [],
      isOpen: false,
      connectionError: useStore.getState().errorMsgConnection || false,
    };
    this.backToMenuHandleCallback = this.backToMenuHandleCallback.bind(this);
    this.loginCallback = this.loginCallback.bind(this);
    this.logoutCallback = this.logoutCallback.bind(this);
  }
  togglePopup = () => {
    this.setState({
        isOpen: !this.state.isOpen
    });
  }
  logoutCallback(){
    this.togglePopup();
    this.setState({
      onClickControl: this.state.onClickControl-1,
      isOpen: true,
      mainmenu: true,
    })
    this.forceUpdate();
  }
  loginCallback(){
    this.forceUpdate();
  }

  backToMenuHandleCallback(){
    this.setState({
      onClickControl: this.state.onClickControl-1,
      mainmenu: true,
      start: false,
      resume: false,
    })
    this.forceUpdate();
  }

  // componentDidUpdate(prevProps, prevState){
  //   if (prevState.connectionError !== this.state.connectionError) {
  //     this.setState({
  //       connectionError: useStore.getState().errorMsgConnection
  //     })
  //     this.forceUpdate();
  //   }
  // }
 
  componentDidMount(){
    // api.subscribeToTimer((err, timestamp) => this.setState({
    //     timestamp
    //   }));
    // getCountriesList();
    getRegions();
    getCountries();
    setLands();
    startAccount();
      this.setState({
        loading: false
      })
    
  }
  render(){
    let mainmenu = this.state.mainmenu;
    let errorMsgConnection = this.state.errorMsgConnection;
    let load = this.state.loading;
        
        // Can start the game
        if (mainmenu) {
          load = <MainMenu 
            loginCallback={this.loginCallback} 
            logoutCallback={this.logoutCallback} 
            mainmenu={mainmenu} 
            errorMsgConnection={errorMsgConnection}/>
        } 
     
        return (
        <View style={styles.container} mainmenu={this.state.onClickControl} >
            {load}
        </View>
        )
  }
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black'
  },
  header: {
    padding: 20
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginVertical: '1em',
    textAlign: 'center'
  },
  text: {
    lineHeight: '1.5em',
    fontSize: '1.125rem',
    marginVertical: '1em',
    textAlign: 'center'
  },
  link: {
    color: '#1B95E0'
  },
  code: {
    fontFamily: 'monospace, monospace'
  }
});