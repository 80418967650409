import React, { useEffect, useState } from "react";
import { acceptLandsOffer, cancelLandsOffer, collectLandsOffer, declineLandsOffer, getLandsOffers, getOwnedLands, updateProfile } from "../Helpers";
import useStore from "../Store";
import AgentPopup from "./AgentPopup";

 
const MyAgent = (props) => {
    const landsOffers = useStore((state) => state.landsOffers);
    const userId = useStore((state) => state.userId);
    const [listOffers, setListOffers] = useState(props.offers)
    const cash = useStore((state) => state.money);
    const setcash  = (value) => useStore.setState((state) => state.money = value);
    const countries = useStore((state) => state.countriesList);
   
    const [formSub, setformSub] = useState(false)
        async function handleCancel(e) {
          e.preventDefault()
          e.target.disabled = true;
          let id = parseInt(e.target.getAttribute('offerid'));
          let price = parseFloat(e.target.getAttribute('price'));
          let buyerid = parseInt(e.target.getAttribute('buyerid'));
        //   updateProfileBuyer(buyerid, price);
          await declineLandsOffer(id)
          await getLandsOffers();
        }
      
        async function handleAccept(e){
                e.preventDefault()
                e.target.disabled = true;
                let price = parseFloat(e.target.getAttribute('price'));
                let buyerid = parseInt(e.target.getAttribute('buyerid'));
                let id = parseInt(e.target.getAttribute('offerid'));
                let landId = parseInt(e.target.getAttribute('landid'));
                let countryId = parseInt(e.target.getAttribute('countryid'));
                // set cash and update the account 
                setcash((cash + parseInt(price)));
                await updateProfile();
                await acceptLandsOffer(id, price, landId, countryId, buyerid, props.sellerId);
                await getLandsOffers();
                await getOwnedLands();
        }
   
    async function cancelOffer(e){
        e.stopPropagation();
        e.target.disabled = true;
        let price = parseFloat(e.target.getAttribute('price'));
        setcash((cash + (price)));
        await updateProfile();
        cancelLandsOffer(parseInt(e.target.getAttribute('offerId')))
        getLandsOffers();
        }  
      
        async function collectMoney(e){
                e.preventDefault()
                e.target.disabled = true;
                let price = parseFloat(e.target.getAttribute('price'));
                let buyerid = parseInt(e.target.getAttribute('buyerid'));
                let id = parseInt(e.target.getAttribute('offerid'));
                // set cash and update the account 
                setcash((cash + parseInt(price)));
                await updateProfile();
                await collectLandsOffer(id);
                await getLandsOffers();
                await getOwnedLands();
        }

        let listOfOffersPost = [];
        let agentMessage = []; 
        let countNow = 0;

     
        if (landsOffers.length !== countNow) {
            landsOffers.forEach((element, xds) => {
                if (element.buyerId === useStore.getState().userId || element.sellerId === useStore.getState().userId) {
                    countNow = landsOffers.length;
                    listOfOffersPost[xds] = element;
                }
            });
        }

       
       listOfOffersPost.forEach(element => {
                if (element.buyerId === useStore.getState().userId && element.status === 0) {
                    agentMessage.push(<li key={(element.id*-6666)} > User {(element.sellerId === 0) ? 1234 : element.sellerId} declined your offer {parseFloat(element.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$ <button price={element.price} offerid={element.id}  buyerid={element.sellerId} onClick={collectMoney} >Get Money Back</button></li>);
                }else if(element.sellerId === useStore.getState().userId && element.status === 1){
                    agentMessage.push(<li key={(element.id*-6666)} > User {element.buyerId} offers {parseFloat(element.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$ for {element.landId} in { countries[parseInt(element.countryId)].name}: <button price={element.price} offerid={element.id} countryid={ element.countryId }  landid={ element.landId } buyerid={element.buyerId} onClick={handleAccept} >Accept</button><button  price={element.price} offerid={element.id} buyerid={element.buyerId} onClick={handleCancel}>Reject</button></li>);
                }else if(element.buyerId === useStore.getState().userId && element.status === 1){
                    agentMessage.push(<li key={(element.id*-6666)} > Your Offer {parseFloat(element.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$ for {element.landId}: <button offerid={element.id} price={ element.price }   onClick={cancelOffer} >Cancel Offer</button></li>);
                }
            });
        
        if(userId && userId !== 0){
            return <AgentPopup visitPropOwned={props.visitPropOwned} list={agentMessage.length} content={agentMessage} header={<><h1>Hello {useStore.getState().username}, This is your agent</h1></>} />
        }else{
            return [];
        }

};

export default MyAgent;