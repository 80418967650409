import bgAudio from './bg.mp3'
import clickAudio from './click.mp3'
import notificationAudio from './notification.wav'
import citySound from './city-sound.mp3'

const bg = new Audio(bgAudio)
const city = new Audio(citySound)
const click = new Audio(clickAudio)
const notification = new Audio(notificationAudio)

export { notification, bg, click, city }
