import React, { useEffect, useState } from "react";
import { updateProfile, userMission } from "../Helpers";
import useStore from "../Store";
import MissionPopup from "./MissionPopup";

 
const MyMissions = (props) => {
    const missions = useStore((state) => state.missions);
    const myMission = useStore((state) => state.myMission);
    const userId = useStore((state) => state.userId);
    const [statusNow, setstatusNow] = useState(0)
    const [zoomNow, setzoomNow] = useState(props.zoomNow)
    const cash = useStore((state) => state.money);
    const missionsCash = useStore((state) => state.missionsCash);
    const setcash  = (value) => useStore.setState((state) => state.money = value);
    const [formSub, setformSub] = useState(false)
    const selectedId = useStore((state) => state.countrySelectedId);
    const landsOwned = useStore((state) => state.landsOwned);
    const [cashNow, setcashNow] = useState(missionsCash)
    const landsOffers = useStore((state) => state.landsOffers);

    let listOfMissions = [];
    let missionMsg = []; 

    let countNow = 0;

        async function collectMoney(e){
                e.preventDefault()
                e.target.disabled = true;
                let price = parseFloat(myMission.level)*5000;
                // set cash and update the account 
                let missionDone = parseInt(e.target.keydelete);
                setcash((cash + parseInt(price)));
                setstatusNow(false);
                setcashNow(missionsCash);
                missionMsg.splice(missionDone, 1);
                countNow=0;
                await updateProfile();
                await userMission({level: parseInt(myMission.level)+1, status: 0, updated_at: Date.now()});
        }
       
        useEffect(() => {
          //  Level 1 swap
            if (myMission.level === 1 && parseInt(zoomNow[0]) - parseInt(props.zoomNow[0]) > 1 ) {
                    setstatusNow(true)
            }
            //  Level 2 zoom
            else if (myMission.level === 2 && selectedId > 0) {
                setstatusNow(true)
            }
             //  Level 3 zoom
             else  if (myMission.level === 3 && landsOwned.length > 0) {
                setstatusNow(true)
            }
             //  Level 4  collect income
             else  if (myMission.level === 4 && missionsCash > cashNow) {
                setstatusNow(true)
                
             }
             //  Level 5 zoom coolect income
             else  if (myMission.level === 5 && missionsCash > cashNow) {
                setstatusNow(true)
             }
              //  Level 6 zoom coolect income
              else   if (myMission.level === 6) {
                let offerAccepted = landsOffers.forEach(x => x.buyerId === useStore.getState().userId && x.status !== 0)
                if (offerAccepted !== -1) {
                    setstatusNow(true)
                }
             }
            
              //  Level 7 zoom sell higher than bought
              else if (myMission.level === 7 && useStore.getState().missionsHigherThanBought !== false) {
                    setstatusNow(true)
             }
              //  Level 8 zoom buy Gas Station
              else if (myMission.level === 8 && landsOwned.findIndex(x => x.type === 'gas') !== -1) {
                setstatusNow(true)
             }
               //  Level 9 have more than 5 properties
               else if (myMission.level === 9 && landsOwned.length >= 5) {
                setstatusNow(true)
             }
              //  Level 10 have more than 10 properties
              else if (myMission.level === 10 && landsOwned.length >= 10) {
                setstatusNow(true)
             }
              //  Level 11 have more than 15 properties
              else if (myMission.level === 11 && landsOwned.length >= 15) {
                setstatusNow(true)
             }
              //  Level 12 have more than 20 properties
              else if (myMission.level === 12 && landsOwned.length >= 20) {
                setstatusNow(true)
             }
               //  Level 13 have more than 30 properties
               else if (myMission.level === 13 && landsOwned.length >= 30) {
                setstatusNow(true)
             }
               //  Level 14 have more than 40 properties
               else if (myMission.level === 14 && landsOwned.length >= 40) {
                setstatusNow(true)
             }
               //  Level 15 have more than 50 properties
               else if (myMission.level === 15 && landsOwned.length >= 50) {
                setstatusNow(true)
             }
               //  Level 16 have more than 1 million
               else if (myMission.level === 16 && parseInt(cash) >= 1000000) {
                setstatusNow(true)
             }
               //  Level 17 have more than 5 million
               else if (myMission.level === 17 && parseInt(cash) >= 5000000) {
                setstatusNow(true)
             }
               //  Level 18 have more than 10 million
               else if (myMission.level === 18 && parseInt(cash) >= 10000000) {
                setstatusNow(true)
             }
               //  Level 19 have more than 5 million, and 2 towers
               else if (myMission.level === 19 && parseInt(cash) >= 5000000) {
                setstatusNow(true)
             }
               //  Level 20 have more than 1 million, and 2 towers
               else if (myMission.level === 20 && parseInt(cash) >= 50000000) {
                setstatusNow(true)
             }
        }, [cash, cashNow, landsOffers, landsOwned, landsOwned.length, missionsCash, myMission.level, props.zoomNow, selectedId, zoomNow])
        
       
        if (countNow !== 1) {          
            missions.forEach((element, xds) => {
                if (element.level === myMission.level) {
                    //    Collect money in status 1
                    const moneyToWin = parseFloat(myMission.level)*5000;
                    const moneyWin = <button keydelete={(element.id*-11111)} price={moneyToWin} onClick={collectMoney} disabled={!statusNow}>Collect</button>
                    countNow = 1;
                    // console.log(element.level + ' level now should show ' + myMission.level)
                    missionMsg.push(<li key={(element.id*-11111)} >{element.mission} to get <b> {moneyToWin}$ {moneyWin} </b></li>);
                }
            });
        }

        
        if(userId && userId !== 0){
            return <MissionPopup statusNow={statusNow} collectMoney={collectMoney} list={missionMsg.length} content={missionMsg} header={<><h1>Missions: </h1></>} />
        }else{
            return [];
        }

};

export default MyMissions;