import create from 'zustand'
import * as Helpers from './Helpers'

const useStore = create((set, get, use) => {
  //  sessionStorage.setItem('data-d', decoded)
 
  return {
    errorMsgConnection: '',
    sound: false,
    camera: undefined,
    score: 0,
    finalSupported: 0,
    finalScore: 0,
    health: 100,
    // User Information
    ipAddress: '',
    galaxy: "",
    email: '',
    name: '',
    level: '1',
    username: '',
    token: '',
    myMission: {},
    missionsCash: 0,
    missionsHigherThanBought: false,
    missionsOwnGasStation: false,
    sentFirst: false,
    apiId: 0,
    userId: 0,
    money: 0,
    investors: [],
    // Will update by API 
    stars_count: 0,
    moneyLastUpdate: 0,
    missions: [],
    actions: {
      
    },
    stockArray:[],
    // countriesList: [
    //     {id:0, x:0.5, y:1, z:-1.9, name:"Asia", population:95486219, color:"purple", landbuyprice: 18000, landsellprice: 12000 , landDress: 'grass'},
    //     {id:1, x:1.1, y:-0.2, z:1.9, name:"South America", population:2512529, color:"green", landbuyprice: 40000, landsellprice: 30000, landDress: 'grass'},
    //     {id:2, x:-0.3, y:1.1, z:1.9, name:"North America", population:2654801, color:"blue", landbuyprice: 60000, landsellprice: 55000, landDress: 'grass'},
    //     {id:3, x:-0.7, y:-1.1, z:-1.7, name:"Australia", population:9845150, color:"gold", landbuyprice: 15000, landsellprice: 10000, landDress: 'grass'},
    //     {id:4, x:1.4, y:1.4, z:-0.5, name:"Europe", population:4861205, color:"pink", landbuyprice: 30000, landsellprice: 25000, landDress: 'snow'},
    //     {id:5, x:1.5, y:1, z:-1, name:"Middle East", population:9456112, color:"yellow", landbuyprice: 20000, landsellprice: 15000, landDress: 'desert'},
    //     {id:6, x:1.8, y:0.5, z:-1, name:"Africa", population:6548612, color:"silver", landbuyprice: 12000, landsellprice: 8000, landDress: 'desert'}                   
    // ],
    countriesList: [],
    regionsList: [],
    landsOffers: [],
    // Countries states
    regionSelected: '',
    regionSelectedPopulation: 0,
    regionSelectedColor: '',
    regionSelectedInvestors: 0,
    regionSelectedInvestment: 0,
    regionSelectedId: 0,
    countrySelected: null,
    countrySelectedPopulation: 0,
    countrySelectedColor: '',
    countrySelectedInvestors: 0,
    countrySelectedInvestment: 0,
    countrySelectedId: 0,
    // Countries lands
    selectedLandPosition:{},
    world0: [],
    landDress: 'grass',
    selectedLand: 0,
    isActiveLand: false,
    isActiveOwnedLand: false,
    // land been sold
    soldLands: [],
    // Lands owned by User
    landsOwned: [],
    connected: false,
    stockMarket: [],
  }
})


export default useStore

