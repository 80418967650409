import useStore from '../Store';
import openSocket from 'socket.io-client';
import { getMissions, getOwnedLands, getSoldLands, startAccount, updateMoneyPerUser, updateProfile, userMission } from '../Helpers';


let token; 
// local
// var url = process.env.REACT_APP_STREAM + ( window.location.protocol === "https:" ? ":3033" : ":3032" );
var url = process.env.REACT_APP_STREAM ;
const socket = openSocket(url, {
    autoConnect: true,
    reconnection: true,
    reconnectionDelay: 10000,
    reconnectionDelayMax : 10000,
    reconnectionAttempts: Infinity,
    forceNew:true
  });


// socket.close();
// socket.close();
 // Trigger the server as client
//  socket.emit('connection');

//  socket.emit('updateOwnSoldLands');
//  socket.emit('updateSoldLands');
//  socket.emit('updateLandsOffers');
//  socket.emit('moneyUpdate');
// either by directly modifying the `auth` attribute

// socket.connect();

socket.on("connect", () => {
    sessionStorage.setItem('sessionId', socket.id)
    // console.log('DDKits Server connected ', socket.connected);
    useStore.setState({
        errorMsgConnection: ''
    })
    socket.emit('connection');
    RunAllFunctions(null);
   
});

socket.on("reconnect", () => {
    sessionStorage.setItem('sessionId', socket.id)
    // console.log('DDKits Server connected ', socket.connected);
    useStore.setState({
        errorMsgConnection: ''
    })
    socket.emit('connection');
    RunAllFunctions(null);
});
socket.on('disconnect', () => {
    useStore.setState({
        errorMsgConnection: 'Connecting to DDkits server please wait'
    })
    //  Do the reconncetion again
     let reconnect = socket.connected;
     if (!reconnect) {
         useStore.setState({
             errorMsgConnection: 'Reconnecting to DDkits server please wait'
         })
     }
     
  
}) 
socket.on('connection_error', () => {
    useStore.setState({
        errorMsgConnection: 'Connecting to DDkits server please wait'
    })
    // Do the reconncetion again
    let reconnect = socket.connected;
    if (!reconnect) {
        useStore.setState({
            errorMsgConnection: 'Reconnecting to DDkits server please wait'
        })
        
    }

}) 

export function userMoneyUpdate(callback, userIdNext = false) {
    let userId = useStore.getState().userId;
    let username = useStore.getState().username;
    if (userId !== 0) {
        socket.auth = { userId:userId, username:username };
    }
    if (userId !== 0) {
        token = socket.id;
        socket.on('money', (money) => {
           return callback(null, money) // world
          });
        //   socket.emit('connection');
        // socket.emit('moneyUpdate');
        // socket.off('money');
    }
  }

export function updateSoldLands(callback) {
    if( socket.connected === false){
        setTimeout(function(){ return  socket.connect();}, 3000);
    }
    //
    let userId = useStore.getState().userId;
    let username = useStore.getState().username;
    token = socket.id; 
    socket.auth = { userId:userId, username:username };
    socket.on('lands',(lands) => {
        return callback(null, lands) // world
      });
    // socket.emit('connection');
    // socket.emit('updateSoldLands');
    // console.log(socket.on('lands', lands => lands))
    // socket.off('lands');

}
export function updateLandsOffers(callback) {
    if( socket.connected === false){
        setTimeout(function(){ return  socket.connect(); }, 3000);
    }
    let userId = useStore.getState().userId;
    let username = useStore.getState().username;
    if (userId !== 0) {
        token = socket.id;
        socket.auth = { userId:userId, username:username };
        socket.on('landsOffers', (landsOffers) => {
           return callback(null, landsOffers) // world
          });
        // socket.emit('connection');
        
        // socket.off('landsOffers');
    }
}
export function updateOwnedLands(callback) {
    if( socket.connected === false){
        setTimeout(function(){ return socket.connect();}, 3000);
    }
    let userId = useStore.getState().userId;
    let username = useStore.getState().username;
    if (userId !== 0) {
        token = socket.id;
        socket.auth = { userId:userId, username:username };
        socket.on('mylands',(mylands) => {
           return callback(null, mylands) // world
          });
        //   socket.emit('connection');
        // socket.emit('updateOwnSoldLands', 5000);
        // socket.off('mylands');
    }
}

export async function RunAllFunctionsAfterLogin(){
    // run back the functions below 
    socket.disconnect();
    await startAccount();
    await userMoneyUpdate(() => {});
    await updateSoldLands(() => {});
    await updateOwnedLands(() => {});
    await updateLandsOffers(() => {});
    await getOwnedLands();
    await updateMoneyPerUser();
    await getMissions();
    await userMission();
    // await updateStockMarket();
    await updateProfile();
}
export async function RunAllFunctions(){
    // run back the functions below 
    await startAccount();
    await getSoldLands();
    // await userMoneyUpdate(() => {});
    // await updateSoldLands(() => {});
    // await updateLandsOffers(() => {});
    // await updateOwnedLands(() => {});
}
  
   