import { Canvas } from "@react-three/fiber";
import React, { startTransition, Suspense, lazy } from "react";
// import "./style.css";
import { Stars, useProgress } from "@react-three/drei";
import styled, { css, createGlobalStyle } from 'styled-components'
import Login from "../../core/Login/Login";
import SignUp from  "../../core/Login/SignUp";
import {decode} from 'base-64';
import Notification from "../../core/Notification";
import useStore from "../../core/Store";
import GameLoader from "../GameLoader";
import { Keyboard, View } from "react-native";
import ErrorBoundary from "../ErrorBoundary";
import { bg } from "../audio";
import Controls from "../controllers";
import MarketStock from "./marketstock";
import MyAgent from "../../core/agent";
import MyMissions from "../../core/missions";
import { BsFillVolumeMuteFill, BsFillVolumeUpFill } from "react-icons/bs";
import { audio, getRegions, playAudio, startAccount,  updateStockMarket } from "../../core/Helpers";
import { RunAllFunctions, RunAllFunctionsAfterLogin } from "../../core/api";
// import City from "../cities";
// import Earth from '../earth';
// import Regions from '../regions';


const Earth = lazy(() => import('../earth'));
const City = lazy(() => import("../cities"));
// const Countries = lazy(() => import('../countries'));
const Regions = lazy(() => import('../regions'));

// Effects
export default class MainMenu extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      gamePause: false,
      resume: false,
      sound: false,
      userId: useStore.getState().userId || 0,
      token: useStore.getState().token || '',
      username: useStore.getState().username || 'NN',
      signup:false,
      login: true,
      loggedIn: false,
      loadingDone: false,
      error: '',
      activeMission: '',
      cityViewActive: false,
      countriesViewActive: false,
      zoomNow: [0,0,5],
      zoom: false,
      focus: false,
      quaternionNow: []
    }
    this.cityViewCallback = this.cityViewCallback.bind(this)
    this.switchSound = this.switchSound.bind(this)
    this.cityViewAgentCallback = this.cityViewAgentCallback.bind(this)
    this.countriesViewCallback = this.countriesViewCallback.bind(this)
    this.handleNotificationCallback = this.handleNotificationCallback.bind(this)
    this.getToken = this.getToken.bind(this)
    this.signupCallback = this.signupCallback.bind(this)
    this.loginCallback = this.loginCallback.bind(this)
    this.logOutCallBack = this.logOutCallBack.bind(this)
    this.zoomChangedCallback = this.zoomChangedCallback.bind(this)
    this.getZoomCountryCallback = this.getZoomCountryCallback.bind(this)
    this.quaternionChangedCallback = this.quaternionChangedCallback.bind(true)
  }

  quaternionChangedCallback = (quaternion) => {
    this.setState({
      quaternionNow: quaternion
    })
  }
  zoomChangedCallback = (zoom) => {
    this.setState({
      zoomNow: zoom
    })
  }
  getZoomCountryCallback(zoom, focus){
    this.setState({
      zoom: zoom,
      focus: focus
    })
    
  }
  cityViewAgentCallback(){
    startTransition(()=> {
      this.countriesViewCallback();
    })
    setTimeout(() => {
         this.cityViewCallback();
    }, 500);
      
  }
  cityViewCallback(){
    startTransition(()=> {
      if(this.state.sound){
        playAudio(audio.city, 0.1, true);
      }
      
      this.setState({
        loadingDone: false,
        cityViewActive: !this.state.cityViewActive
      });
      if(!this.state.cityViewActive && this.state.sound){
        audio.city.pause();
      }
    })
    
     // Check server
    this.forceUpdate();
  }
  switchSound(){
    useStore.setState((state) => state.sound = !this.state.sound);
    this.setState({
      sound: !this.state.sound
    })
    setTimeout(() => {
      this.playAudioMain(this.state.sound);
    }, 500);
    
  }
  countriesViewCallback(){
    audio.city.pause();
    startTransition(()=> {
      useStore.setState((state) => state.sound = !state.sound);
      this.setState({
        loadingDone: false,
        countriesViewActive: !this.state.countriesViewActive
      });
    })
    
     // Check server
    this.forceUpdate();
    
  }

  playAudioMain(play = true){
      if (play) {
        bg.currentTime = 0;
        bg.volume = 0.15;
        bg.loop = true;
        // bg.muted = true;
        bg.play()
      }else{
        bg.pause();
        audio.city.pause();
      }
  }
  logOutCallBack(){
      document.body.style.zoom = "100%";
      sessionStorage.clear();
      localStorage.clear();
      Keyboard.dismiss();
      this.props.logoutCallback();
     this.setState({
        signup: false,
        loggedIn: false,
        login: true
      })
      window.location.reload(false);
      // this.forceUpdate();
  }
  loginCallback(){
    this.setState({
      signup: false,
      login: true
    })
    Keyboard.dismiss();
    document.body.style.zoom = "100%";
    this.props.loginCallback();
    this.forceUpdate();
  }
  signupCallback(){
    this.setState({
      signup: true,
      login: false
    })
  }
  componentDidMount(){
    getRegions();
    useStore.setState((state) => state.sound = false);
    startAccount();
    Keyboard.dismiss();
    document.body.style.zoom = "100%";
    this.getToken();  
    updateStockMarket(); 
    this.setState({
      loadingDone: true
    })
  }

  
  getToken() {
    startAccount();
    Keyboard.dismiss(); 
    document.body.style.zoom = "100%";
    const data = 
    sessionStorage.getItem('data') !== null ? JSON.parse((decode(sessionStorage.getItem('data')))) 
    : localStorage.getItem('data') !== null ? JSON.parse((decode(localStorage.getItem('data')))) : [];
    if (!data || !data.userId) {
      RunAllFunctions();
      return false;
    } else {
     this.setState({
        token: useStore.getState().token,
        username: useStore.getState().username,
        money: useStore.getState().money,
        userId: useStore.getState().userId,
        lands: useStore.getState().landsOwned,
        loggedIn: true,
        login: false,
        signup: false
      })
       RunAllFunctionsAfterLogin();
       this.forceUpdate();
    }
    return;
  }

  authorizedCallback = async (res) => {
    document.body.style.zoom = "100%";
    this.getToken();
    this.forceUpdate();
  }
  handleNotificationCallback(res){
        this.setState({
          error: res,
        })
        setTimeout(() => {
          this.setState({
            error: '',
          })
        }, 4000);
  }


   render(){
        document.body.style.zoom = "100%";
       const token = this.state.token;
       let resumeBtn = '';
       let startBtn ='';
       let resume ='';
       let signUpBtn = '';
       let ownedLands = [];
       let cityViewActive = this.state.cityViewActive;
       let authorized = undefined;
        let focus = this.state.focus;
        let zoom = this.state.zoom;
         if (!this.state.loggedIn && !cityViewActive) {
           startBtn = <button className="vertical-middle " onClick={this.loginCallback}>LogIn</button>
           signUpBtn = <button className="vertical-middle " onClick={this.signupCallback}>SignUp</button>
           if (this.state.signup) {
            authorized = <SignUp visible={this.state.signup}  authorizedCallback={this.authorizedCallback}  handleErrorCallback={this.handleNotificationCallback}/>
           }else if (this.state.login){
            authorized = <Login visible={this.state.login} authorizedCallback={this.authorizedCallback}  handleErrorCallback={this.handleNotificationCallback}/>
           }
        } else {
          if(!cityViewActive){
            signUpBtn = <button onClick={this.logOutCallBack} > Logout </button>
          }
         }    
         
         let canvasNow;         
         if (cityViewActive) {
          canvasNow = <City cityview={this.cityViewCallback} />
          }else{
           
           canvasNow = (<Canvas dpr={window.devicePixelRatio} frameloop='demand' >
           <Suspense fallback={<GameLoader image='assets/images/loadingGrass.png'  />}>
                <Stars radius={300} depth={50} count={1000} factor={7} saturation={0} fade={true}/>
                <Earth />
                <Controls minDistance={3} maxDistance={5} enableRotate={true} orbit={true} zoomChanged={this.zoomChangedCallback} quaternionChanged={this.quaternionChangedCallback} zoom={zoom}  focus={focus}/>
                <Regions cityview={this.cityViewCallback} countriesview={this.countriesViewCallback} zoomNow={this.state.zoomNow} getZoomCountry={this.getZoomCountryCallback}/>
             </Suspense>
             
           </Canvas>);
         }

      // if (!this.state.loadingDone) {
      //   return (<> <div className="justify-content-middle main-menu" style={{
      //     width: "100vw",
      //     height: "100vh"
      //   } }><Canvas dpr={window.devicePixelRatio} >
      //    <GameLoader image='assets/images/loadingGrass.png' /></Canvas></div></>)
      // }
      return (
        <View>
            <div className="justify-content-middle main-menu" style={{
              width: "100vw",
              height: "100vh"
            } }>
            <ErrorBoundary>
              { canvasNow }
            </ErrorBoundary>
            <MyAgent visitPropOwned={this.cityViewAgentCallback}/>
            <MyMissions zoomNow={this.state.zoomNow}/>
          <UpperTitle>
            { !cityViewActive ? 
          <div >
                <p>Real Estates Virtual World</p>
                {/* <p>Buy/Sell Virtual lands</p> */}
            </div>
            
            : []}
          </UpperTitle>

          {/* Stock bar */}
           <UpperRightBar>
                <MarketStock />
            </UpperRightBar>
          <MainMenuStyle>
          <button onClick={this.switchSound} >{(this.state.sound)? <BsFillVolumeUpFill /> : <BsFillVolumeMuteFill />}</button>
          { startBtn }
          { resumeBtn }
          { signUpBtn }
          </MainMenuStyle>
          { (this.state.error !== '') ? <Notification visible={this.state.error} message={this.state.error} visibleCallback={this.visibleCallback} /> : []}<br />
          {authorized}
          </div>
      </View>
    );
   }
   
  }
 
  // function Loader() {
  //   const { active, progress, errors, item, loaded, total } = useProgress()
  //   return <GameLoader progress={progress} total={total} />
  // }

 
  const base = css`
  font-family: 'Teko', sans-serif;
  position: absolute;
  text-transform: uppercase;
  font-weight: 900;
  font-variant-numeric: slashed-zero tabular-nums;
  line-height: 1em;
  color: white;
  z-index: 5;
  & > input{
    margin: 1vw;
  }
  & > input{
    display: block;
    margin: 1vw;
  }
`

const UpperTitle = styled.div`
  ${base}
  top: calc(10vh);
  left: calc(1vw);
  height: auto;
  max-height: 10vh;
  transform: skew(5deg, 5deg);
  pointer-events: none;
  line-height: 1em;
  font-size:  2em;
  & > h1 {
    margin: 0;
    font-size: 6em;
   
  }
  & > h2 {
    margin: 0;
    font-size:  3em;
  }
  & > h3 {
    font-size: 2em;
  }
  & > h4 {
    font-size: 1em;
  }

  @media only screen and (max-width: 1200px) {
    max-height: 10vh;
    font-size: 12px ;
    & > h1 {
      font-size: 14px;
    }
    & > h2 {
      font-size: 12px ;
    }
    & > h3 {
      font-size: 10px ;
    }
    & > h4 {
      font-size: 10px ;
    }
  }
`

const MainMenuStyle = styled.div`
  ${base}
  text-align: right;
  top: calc(10vh);
  right: calc(1vh);
  height: auto;
  font-size: 16px;
  width: auto;
  max-width: 30vw;
  pointer-events: all;
  cursor: pointer;
  max-width: 30vw;
  & > a {
    color: white;
    text-decoration: none;
    
  }
  @media only screen and (max-width: 1200px) {
    top: calc(10vh);
    right: calc(1vh);
    
    font-size: 16px;
    & > button {
      font-size: 10px;
    }
  }
`

const LowerLeft = styled.div`
  ${base}
  text-transform: none;
  pointer-events: none;
  text-align: left;
  top: calc(20vh);
  left: calc(1vw);
  font-size: 14px;
  text-decoration: none;
  max-width: 20vw;
  background: rgb(255, 255,255 ,0.1);
  padding: 1vh;
  border: 1px solid white;
  & > .lands{
    max-height: 10vh;
  }
  & > a {
    color: white;
    text-decoration: none;
    
  }
  & > button {
    color: black;
    text-decoration: none;
    background: rgb(255, 255,255 ,0.8);
    width: 100%;
  }
  & > button:hover {
    color: white;
    text-decoration: none;
    background: rgb(0, 0,0 ,0.8);
  }

  @media only screen and (max-width: 1200px) {
    top: calc(20vh);
  left: calc(1vw);
    width: auto;
    height: 10vh;
    overflow: auto;
    font-size: 6px;
    pointer-events: all;
    cursor: pointer;
    & > button {
      width: auto;
      padding: 3vw;
    }
  }
  
`

const UpperRight = styled.div`
  ${base}
  top: calc(1vh);
  left: calc(1vw);
  max-width: 40vw;
  font-size: 16px;
  background: rgb(0,0,0 ,0.5);
  border: 1px solid white;
  & > .lands{
    max-height: 10vh;
  }
  & > a {
    color: white;
    text-decoration: none;
    
  }
  & > button {
    color: black;
    text-decoration: none;
    width: 100%;
  }
  & > button:hover {
    color: white;
    text-decoration: none;
    background: rgb(0, 0,0 ,0.8);
  }

  @media only screen and (max-width: 1200px) {
    top: calc(1vh);
    left: calc(1vw);
    width: auto;
    height: 10vh;
    overflow-y: auto;
    font-size: 10px !important;
    & > button {
      width: auto;
      padding: 3vw;
    }
  }
`

const UpperRightBar = styled.div`
  ${base}
  top: calc(0vh);
  left: calc(0vw);
  width: 100vw;
  font-size: 12px;
  padding: 1vw;
  display: flex !important;
  justify-content:space-around !important;
  list-style-type:none !important;
  justify-content: middle;
  background: rgb(0,0,0 ,0.8);
  & > a {
    color: white;
    text-decoration: none;
  }
  & > button {
    color: black;
    text-decoration: none;
    width: 100%;
  }
  & > button:hover {
    color: white;
    text-decoration: none;
    background: rgb(0, 0,0 ,0.8);
  }

  @media only screen and (max-width: 1200px) {
    font-size: 6px !important;
  }
`


const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    user-select: none;
  }

  #root {
    padding: 0px;
  }

  body {
    position: fixed;
    overscroll-behavior-y: none;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
    color: black;
    background: white;
  }
`
