import axios from "axios"
import useStore from "./Store"
import * as audio from '../components/audio'
import {decode , encode} from 'base-64';
import * as api from './api'

function playAudio(audio, volume = 1, loop = false, muted = true) {
    if (useStore.getState().sound) {
      audio.currentTime = 0
      audio.volume = volume
      audio.loop = loop
    //   audio.muted = muted
      audio.play()
    } else audio.pause()
  }
  
  // Set lands
  async function setLands(){
      let options = {
          url: process.env.REACT_APP_AUTH + "/cities",
          method: 'GET',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          }
        };
      //   api.subscribeToTimer((err, timestamp) => this.setState({
      //         timestamp
      //       }));
        
       await axios(options)
          .then(
              res => {
                      let lands=[];
                      let result = JSON.parse(res.data)
                          result.forEach((element, index) => {
                                  lands[index] = {
                                      id: element.id,
                                      x: element.x,
                                      y: element.y, 
                                      z: element.z,
                                      type: element.type,
                                      version: element.version,
                                      extra: element.extra,
                                      forsale: element.forsale
                                  }   
                          });
                          useStore.setState({
                              world0: lands
                          });
                          // console.log(lands)
              }
              ).catch(err => 
              console.log(err.message)
          );
      // console.log(result)
  }
  
  // Now start the syncing
  async function sendData(result) {
       // get IP 
       let dataToSend = {
          money: useStore.getState().money,
          level: useStore.getState().level,
          email: useStore.getState().email,
          name: useStore.getState().name,
          ip: useStore.getState().ipAddress,
          id: useStore.getState().apiId
      }
      
      
      if (useStore.getState().apiId > 0) {
          let options = {
              url: process.env.REACT_APP_AUTH + "/user/board",
              method: 'PUT',
              headers: {
                  'cache-control': 'no-cache',
                  'content-type': 'application/json',
                  accept: 'application/json',
              },
              data: JSON.stringify(dataToSend)
            };
          await  axios(options)
          .then().catch(err => 
              console.log(err.message)
          );
         
      } else {
          let options = {
              url: process.env.REACT_APP_AUTH + "/user/board",
              method: 'POST',
              headers: {
                  'cache-control': 'no-cache',
                  'content-type': 'application/json',
                  accept: 'application/json',
              },
              data: JSON.stringify(dataToSend)
            };
          await  axios(options)
          .then().catch(err => 
              console.log(err.message)
          );
      }
  }
  
  async function getMissions(){
      let options = {
              url: process.env.REACT_APP_AUTH + "/missions",
              method: 'GET',
              headers: {
                  'cache-control': 'no-cache',
                  'content-type': 'application/json',
                  accept: 'application/json',
              }
            };
          await axios(options)
          .then(data => {
              let result = JSON.parse(data.data)
              let missionsList=[];
                  result.forEach((element, index) => {
                          missionsList[index] = {
                              id: element.id,
                              level:  element.level, 
                              mission: element.mission
                          }   
                  });
                  let missionsArray = missionsList.filter((a) => a);
                  useStore.setState({
                      missions: missionsArray
                  });
              }).catch(err => 
              console.log(err.message)
          );
  }
  async function userMission(update = {}){
      let userId = useStore.getState().userId;
      let options;
      if (update.level) {
          options = {
              url: process.env.REACT_APP_AUTH + "/missions?userId="+userId,
              method: 'PUT',
              headers: {
                  'cache-control': 'no-cache',
                  'content-type': 'application/json',
                  accept: 'application/json',
              },
              data: JSON.stringify(update)
            };
      }else{
          options = {
              url: process.env.REACT_APP_AUTH + "/missions?userId="+userId,
              method: 'GET',
              headers: {
                  'cache-control': 'no-cache',
                  'content-type': 'application/json',
                  accept: 'application/json',
              }
            };
      }
        await axios(options)
          .then(data => {
              let result = JSON.parse(data.data)
              let missionsList={};
                  result.forEach((element, index) => {
                          missionsList = {
                              id: element.id,
                              level:  element.level, 
                              status: parseInt(element.status)
                          }   
                  });
                  useStore.setState({
                      myMission: missionsList
                  });
              }).catch(err => 
              console.log(err.message)
          );
  }
  async function startAccount(){
      const data = 
          sessionStorage.getItem('data') !== null ? JSON.parse((decode(sessionStorage.getItem('data')))) 
          : localStorage.getItem('data') !== null ? JSON.parse((decode(localStorage.getItem('data')))) : [];
          if (data === []) {
            await useStore.setState({
                  userId: 0,
                  email: '',
                  name: '',
                  money: 0,
                  username: '',
                  token: ''
                })
                return false;
          }else{
           await useStore.setState({
                  userId: parseInt(data.userId),
                  email: data.email,
                  name: data.name,
                  money: parseFloat(data.money) || 0,
                  username: data.username,
                  token: data.token
                })
          }
          return true;
  }
  
  // Now start the syncing
  function updateProfile() {
      axios.get("https://api.ipify.org?format=json")
      .then(data => {
          useStore.setState(state => ({ ipAddress: data.data.ip }))
      });
       // get IP 
       let dataToSend = {
          userId: useStore.getState().userId,
          money: useStore.getState().money,
          ip: useStore.getState().ipAddress
      }
        
      // Update sessions for the latest results
      // session
      let storeData = {
          userid: useStore.getState().name,
          username: useStore.getState().username,
          email: useStore.getState().email,
          token: useStore.getState().token,
          money:  useStore.getState().money,
          userId: useStore.getState().userId,
          ip: useStore.getState().ipAddress
      };
    
      let encoded = encode(JSON.stringify(storeData));
      sessionStorage.setItem('data', encoded)
      localStorage.setItem('data', encoded)
      // Localstorage
    
      let options = {
      url: process.env.REACT_APP_AUTH + "/user/update",
      method: 'PUT',
      headers: {
          'cache-control': 'no-cache',
          'content-type': 'application/json',
          accept: 'application/json',
      },
      data: JSON.stringify(dataToSend)
      };
      axios(options)
      .then().catch(err => 
          console.log(err.message)
      );
          
  
  }
  // Now start the syncing
  function updateProfileBuyer(userId, money) {
       // get IP 
       let dataToSend = {
          userId: userId,
          money: money
      }
      // Localstorage
      let options = {
      url: process.env.REACT_APP_AUTH + "/user/buyerupdate",
      method: 'PUT',
      headers: {
          'cache-control': 'no-cache',
          'content-type': 'application/json',
          accept: 'application/json',
      },
      data: JSON.stringify(dataToSend)
      };
      axios(options)
      .then().catch(err => 
          console.log(err.message)
      );
      
  
  }
  // Now start the syncing
  function updateProfileLands(countryId, landId, position, status, pricebought, pricetosell, type, version, extra, forsale,selling = false, sellingId = 0) {
      let options;
        if (selling) {
              let dataToSend = {
                  id: sellingId
              }
            // Update sessions for the latest results
              options = {
                  url: process.env.REACT_APP_AUTH + "/lands",
                  method: 'DELETE',
                  headers: {
                      'cache-control': 'no-cache',
                      'content-type': 'application/json',
                      accept: 'application/json',
                  },
                  data: JSON.stringify(dataToSend)
              };
        }else{
            // get IP 
              let dataToSend = {
                  countryId: countryId,
                  landId: landId,
                  position: JSON.stringify(position),
                  status: status,
                  userId: useStore.getState().userId,
                  pricebought: pricebought,
                  pricetosell: pricetosell,
                  type: type,
                  version: version,
                  extra: extra,
                  forsale: forsale
              }
              // Update sessions for the latest results
               options = {
                  url: process.env.REACT_APP_AUTH + "/lands",
                  method: 'POST',
                  headers: {
                      'cache-control': 'no-cache',
                      'content-type': 'application/json',
                      accept: 'application/json',
                  },
                  data: JSON.stringify(dataToSend)
                  };
        }
      
      axios(options)
      .then().catch(err => 
          console.log(err.message)
      );
      
  }
  // After money collected reset the counter of profit
  async function updateOwnedLandsProfit(landId, countryId){
      useStore.setState(state => state.missionsCash = 1)
      // now reset the profit for the land storage
      let landsOwned = useStore.getState().landsOwned;
      let soldLands = useStore.getState().soldLands;
      let index = landsOwned.findIndex(land => land.landId === landId && land.countryId === countryId);
      let landNewValue = landsOwned[index];
      let options = {
          url: process.env.REACT_APP_AUTH + "/user/landupdate?landId="+landNewValue.id+"&countryId="+countryId,
          method: 'GET',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          }
          };
      let sold = soldLands.findIndex(land => land.landId === landId && land.countryId === countryId);   
      let landNewSoldValue = soldLands[sold];  
      landNewValue.profit = 0;
      landNewSoldValue.profit = 0;
      landNewValue.updated_at = Date.now();
      landNewSoldValue.updated_at = Date.now();
      landNewValue.col_updated_at = Date.now();
      landNewSoldValue.col_updated_at = Date.now();
      landNewValue.revenue_updated_at = Date.now();
      landNewSoldValue.revenue_updated_at = Date.now();
      landsOwned.splice(index, 1, landNewValue);
      soldLands.splice(sold, 1, landNewSoldValue);  
          
      await axios(options)
          .then(res =>{
              //  now set the new landsOwned in store
              useStore.setState({
                  landsOwned: landsOwned,
                  soldLands: soldLands
              })
           }
              
          ).catch(err => 
              console.log(err.message)
          );
  
  
  }
  async function getOwnedLands(){
      const data = 
          sessionStorage.getItem('data') !== null ? JSON.parse((decode(sessionStorage.getItem('data')))) 
          : localStorage.getItem('data') !== null ? JSON.parse((decode(localStorage.getItem('data')))) : [];
      const userNow = data.userId;
      if (data.userId) {
          let options = {
              url: process.env.REACT_APP_AUTH + "/lands?userId="+parseInt(data.userId),
              method: 'GET',
              headers: {
                  'cache-control': 'no-cache',
                  'content-type': 'application/json',
                  accept: 'application/json',
              },
              data: JSON.stringify({ userid: parseInt(data.userId) })
              };
          await axios(options)
              .then(data => {
                      let result = JSON.parse(data.data)
                      let landsOwned=[];
                          result.forEach((element, index) => {
                                  landsOwned[index] = {
                                      id: element.id,
                                      countryId:  element.countryId, 
                                      position: JSON.parse(element.position), 
                                      landId: element.landId, 
                                      owner:  element.userId,
                                      type: element.type,
                                      version: element.version,
                                      extra: element.extra,
                                      forsale: element.forsale,
                                      profit: parseFloat(element.profit),
                                      updated_at: element.updated_at,
                                      pricebought: parseFloat(element.pricebought),
                                      revenue_updated_at: element.revenue_updated_at,
                                      col_updated_at: element.col_updated_at
                                  }   
                          });
                          useStore.setState({
                              landsOwned: landsOwned
                          });
                      }
              ).catch(err => 
                  console.log(err.message)
              );
      }else{
          useStore.setState({
              landsOwned: []
          });
      }
     
  }
  async function getRegions(){
      let options = {
          url: process.env.REACT_APP_AUTH + "/regions",
          method: 'GET',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          }
          };
      await axios(options)
          .then(data => {
                  let result = JSON.parse(data.data)
                  let regions=[];
                      result.forEach((element, index) => {
                          regions[element.id] = {
                                  id: parseInt(element.id),
                                  countryId: parseInt(element.id), 
                                  x: parseFloat(element.x), 
                                  y: parseFloat(element.y), 
                                  z: parseFloat(element.z), 
                                  name: element.name, 
                                  population: parseFloat(element.population), 
                                  color: element.color, 
                                  landbuyprice: parseFloat(element.landbuyprice), 
                                  landsellprice: parseFloat(element.landsellprice), 
                                  landDress: element.landDress
                              }   
                      });
                      let regionsArr = regions.filter((a) => a);
                      useStore.setState({
                          regionsList: regionsArr
                      });
                  }
          ).catch(err => 
              console.log(err.message)
          );
  }
  async function getCountries(){
      let region = (useStore.getState().regionSelected !== null) ? useStore.getState().regionSelected : '';
      let options = {
          url: process.env.REACT_APP_AUTH + "/countries?region="+region,
          method: 'GET',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          }
          };
      await axios(options)
          .then(data => {
                  let result = JSON.parse(data.data)
                  let countries=[];
                      result.forEach((element, index) => {
                              countries[element.id] = {
                                  id: parseInt(element.id),
                                  countryId: parseInt(element.id), 
                                  x: parseFloat(element.x), 
                                  y: parseFloat(element.y), 
                                  z: parseFloat(element.z), 
                                  name: element.name, 
                                  region: element.region, 
                                  population: parseInt(element.population), 
                                  color: element.color, 
                                  landbuyprice: parseInt(element.landbuyprice), 
                                  landsellprice: parseInt(element.landsellprice), 
                                  landDress: element.landDress
                              }   
                      });
                      // let countriesArr = countries.filter((a) => a);
                      useStore.setState({
                          countriesList: countries
                      });
                  }
          ).catch(err => 
              console.log(err.message)
          );
  }
  
  async function submitLandsOffer(price = 0, landId, countryId, buyerId, sellerId){
      //mission 5
      if(countryId === 115 && parseInt(sellerId) === 0){
          useStore.setState(state => state.missionsCash = 1)
      }
      let statusCheck = (parseInt(sellerId) === 0) ? 0 : 1;
      let options = {
          url: process.env.REACT_APP_AUTH + "/landsoffers",
          method: 'POST',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          },
          data: JSON.stringify({ countryId: countryId, status: statusCheck, sellerId: sellerId, buyerId: buyerId, landId: landId, price: price, updated_at: Date.now()  })
          };
      await axios(options)
          .then(
          ).catch(err => 
              console.log(err.message)
          );
           // session
           let storeData = {
              userid: useStore.getState().name,
              username: useStore.getState().username,
              email: useStore.getState().email,
              token: useStore.getState().token,
              money:  useStore.getState().money,
              moneyLastUpdate: useStore.getState().updated_at,
              userId: useStore.getState().userId,
              ip: useStore.getState().ipAddress
          };
    
      let encoded = encode(JSON.stringify(storeData));
      sessionStorage.setItem('data', encoded)
      localStorage.setItem('data', encoded)
  }
  
  async function cancelLandsOffer(id){
      let options = {
          url: process.env.REACT_APP_AUTH + "/landsoffers?id="+id,
          method: 'DELETE',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          },
          data: JSON.stringify({ id: id })
          };
      await axios(options)
          .then(
          ).catch(err => 
              console.log(err.message)
          );
          // session
          let storeData = {
              userid: useStore.getState().name,
              username: useStore.getState().username,
              email: useStore.getState().email,
              token: useStore.getState().token,
              money:  useStore.getState().money,
              moneyLastUpdate: useStore.getState().updated_at,
              userId: useStore.getState().userId,
              ip: useStore.getState().ipAddress
          };
    
      let encoded = encode(JSON.stringify(storeData));
      sessionStorage.setItem('data', encoded)
      localStorage.setItem('data', encoded)
  }
  
  async function declineLandsOffer(id){
      let options = {
          url: process.env.REACT_APP_AUTH + "/landsoffers?id="+id,
          method: 'PUT',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          },
          data: JSON.stringify({ id: id })
          };
      await axios(options)
          .then(
          ).catch(err => 
              console.log(err.message)
          );
  }
  
  
  async function acceptLandsOffer(id, price, landId,  countryId,  buyerId,  sellerId){
      let options = {
          url: process.env.REACT_APP_AUTH + "/landsoffers",
          method: 'PUT',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          },
          data: JSON.stringify({ 
              id:id,
              price: parseFloat(price), 
              landId:landId, 
              countryId:countryId,  
              buyerId:buyerId,  
              sellerId:sellerId})
          };
      await axios(options)
          .then(
          ).catch(err => 
              console.log(err.message)
          );
          // session
          let storeData = {
              userid: useStore.getState().name,
              username: useStore.getState().username,
              email: useStore.getState().email,
              token: useStore.getState().token,
              money:  useStore.getState().money,
              moneyLastUpdate: useStore.getState().updated_at,
              userId: useStore.getState().userId,
              ip: useStore.getState().ipAddress
          };
    
      let encoded = encode(JSON.stringify(storeData));
      sessionStorage.setItem('data', encoded)
      localStorage.setItem('data', encoded)
  }
  
  async function collectLandsOffer(id){
      let options = {
          url: process.env.REACT_APP_AUTH + "/landsoffers?id="+id,
          method: 'DELETE',
          headers: {
              'cache-control': 'no-cache',
              'content-type': 'application/json',
              accept: 'application/json',
          }};
      await axios(options)
          .then(
          ).catch(err => 
              console.log(err.message)
          );
  }
  async function getOwnedLandsSync(){
      await api.updateOwnedLands((err, soldLands) => {
        if(err){
            console.log(err)
        }
              let soldLandsNow=[];
              let landsOwnedNow=[];
              if(soldLands.length > 0){
               
                soldLands.forEach((element, index) => {
                    // set ownedLands if logged in
                    if (parseInt(element.userId) === useStore.getState().userId) {
                        landsOwnedNow[index] = {
                            id: element.id,
                            countryId:  parseInt(element.countryId), 
                            position: JSON.parse(element.position), 
                            landId: parseInt(element.landId), 
                            owner:  parseInt(element.userId),
                            type: element.type, 
                            extra: parseFloat(element.extra), 
                            version: parseInt(element.version), 
                            forsale: parseInt(element.forsale),
                            profit: parseFloat(element.profit),
                            pricebought: parseFloat(element.pricebought),
                            updated_at: element.updated_at,
                            revenue_updated_at: element.revenue_updated_at,
                            col_updated_at: element.col_updated_at
                        }   
                    }
                });
    
                // Clean update the investors list
                let owned = landsOwnedNow.filter((a) => a);
                useStore.setState({
                    landsOwned: owned
                });
              }             
              
      });
  }
  async function getSoldLands(){
   
    await api.updateSoldLands((err, soldLands) => {
        if(err){
            console.log(err)
        }
        let soldLandsNow=[];
        let investorsNow = [];
            if(soldLands.length > 0){
               
                soldLands.forEach((element, index) => {
                  // Investors 
                  if (investorsNow.length > 0 && investorsNow[parseInt(element.countryId)]) {
                      investorsNow[parseInt(element.countryId)] = {countryId: parseInt(element.countryId), investors: investorsNow[element.countryId].investors+1};
                  }else{
                      investorsNow[parseInt(element.countryId)] = {countryId: parseInt(element.countryId), investors: 1};
                  }
                  // set sold lands
                  soldLandsNow[index] = {
                      id: element.id, 
                      countryId:  parseInt(element.countryId), 
                      position: JSON.parse(element.position), 
                      landId: parseInt(element.landId), 
                      owner:  parseInt(element.userId),
                      userId: parseInt(element.userId), 
                      type: element.type, 
                      extra: parseFloat(element.extra), 
                      version: parseInt(element.version), 
                      forsale: parseInt(element.forsale)
                  }   
                  
              });
  
              // Clean update the investors list
              let arr = investorsNow.filter((a) => a);
              let sold = soldLandsNow.filter((a) => a);
              

              useStore.setState({
                  investors: arr,
                  soldLands: sold
              });
            }
    });
}
  async function getLandsOffers(){
      await api.updateLandsOffers((err, landsOffersNow) => {
        if(err){
            console.log(err)
        }
            let lands=[];
            let landsOffersList=[];
            if(landsOffersNow.length > 0){
              landsOffersNow.forEach((element, index) => {
                  // set ownedLands if logged in
                  landsOffersList[index] = {
                          id: element.id,
                          countryId:  parseInt(element.countryId), 
                          landId: parseInt(element.landId), 
                          buyerId:  parseInt(element.buyerId),
                          sellerId: parseInt(element.sellerId),
                          price: parseInt(element.price),
                          status: parseInt(element.status),
                          updated_at: parseInt(element.updated_at)
                      }
              });
  
              // Clean update the investors list
              let offers = landsOffersList.filter((a) => a);
              useStore.setState({
                  landsOffers: offers
              });
            }
      });
  }
  async function updateMoneyPerUser(){
      await api.userMoneyUpdate((err, results) => {
              if(err){
                  console.log(err) 
              }else{
                  let result = results;
                  useStore.setState({
                      money: result.money,
                      moneyLastUpdate: result.updated_at,
                  });
                  // Update sessions for the latest results
                  // session
                  let storeData = {
                      userid: useStore.getState().name,
                      username: useStore.getState().username,
                      email: useStore.getState().email,
                      token: useStore.getState().token,
                      money:  result.money,
                      moneyLastUpdate: result.updated_at,
                      userId: useStore.getState().userId,
                      ip: useStore.getState().ipAddress
                  };
              
                  let encoded = encode(JSON.stringify(storeData));
                  sessionStorage.setItem('data', encoded)
                  localStorage.setItem('data', encoded)
              }
              
          });
    
  
  }
  
  async function updateStockMarket(){
    let options = {
        url: process.env.REACT_APP_AUTH + "/stockmarket",
        method: 'GET',
        headers: {
            'cache-control': 'no-cache',
            'content-type': 'application/json',
            accept: 'application/json',
        }
        };
    await axios(options)
        .then(data => {
                let result = JSON.parse(data.data)
                let stocks = [];
                  result.forEach(element => {
                      stocks[element.id] = {
                          buyPriceBefore: parseFloat( element.buyPriceBefore),
                          buyPriceNow: parseFloat( element.buyPriceNow),
                          sellPriceBefore: parseFloat(element.sellPriceBefore),
                          sellPriceNow: parseFloat(element.sellPriceNow),
                          avgBefore: parseFloat(element.avgBefore),
                          avg: parseFloat(element.avg),
                          countryName: element.countryName,
                          countryId: parseInt(element.countryId),
                          updated_at: element.updated_at,
                          investors: parseInt(element.investors)
                      }
                  });
                  let stocksArr = stocks.filter((a) => a);
                  useStore.setState({
                      stockArray: stocksArr,
                  }); 
                }
        ).catch(err => 
            console.log(err.message)
        );
    //   await api.getStockMarket((err, results) => {
    //           if(err){
    //               console.log(err) 
    //           }else{
    //               let stocks = [];
    //               let result = JSON.parse(results)
    //               result.forEach(element => {
    //                   stocks[element.id] = {
    //                       buyPriceBefore: parseFloat( element.buyPriceBefore),
    //                       buyPriceNow: parseFloat( element.buyPriceNow),
    //                       sellPriceBefore: parseFloat(element.sellPriceBefore),
    //                       sellPriceNow: parseFloat(element.sellPriceNow),
    //                       avgBefore: parseFloat(element.avgBefore),
    //                       avg: parseFloat(element.avg),
    //                       countryName: element.countryName,
    //                       countryId: parseInt(element.countryId),
    //                       updated_at: element.updated_at,
    //                       investors: parseInt(element.investors)
    //                   }
    //               });
    //               let stocksArr = stocks.filter((a) => a);
    //               useStore.setState({
    //                   stockArray: stocksArr,
    //               }); 
    //           }
    //       });
  }
  
  function getCurrentDate(separator=''){
  
      let newDate = new Date()
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let hours = newDate.getHours();
      let minutes = newDate.getMinutes();
  
      return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date} - ${hours}:${minutes}`
  }
  
    function time_ago(time) {
  
      switch (typeof time) {
        case 'number':
          break;
        case 'string':
          time = +new Date(time);
          break;
        case 'object':
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, 'seconds', 1], // 60
        [120, '1 minute ago', '1 minute from now'], // 60*2
        [3600, 'minutes', 60], // 60*60, 60
        [7200, '1 hour ago', '1 hour from now'], // 60*60*2
        [86400, 'hours', 3600], // 60*60*24, 60*60
        [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
        [604800, 'days', 86400], // 60*60*24*7, 60*60*24
        [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
        [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
        [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
        [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
        [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = 'ago',
        list_choice = 1;
    
      if (seconds == 0) {
        return 'Just now'
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = 'from now';
        list_choice = 2;
      }
      var i = 0,
        format;
      while (format = time_formats[i++])
        if (seconds < format[0]) {
          if (typeof format[2] === 'string')
            return format[list_choice];
          else
            return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
        }
      return time;
    }
    function someMethodIThinkMightBeSlow() {
      const startTime = performance.now();
      // Do the normal stuff for this function
      const duration = performance.now() - startTime;
      // console.log(`someMethodIThinkMightBeSlow took ${duration}ms`);
  }
  
  function gasStationLand(id){
      // console.log(id)
      const lands = [
          { id: 7 },   { id: 16 },  { id: 25 },
          { id: 34 },  { id: 43 },  { id: 52 },
          { id: 70 },  { id: 79 },  { id: 88 },
          { id: 97 },  { id: 106 }, { id: 115 },
          { id: 133 }, { id: 142 }, { id: 157 },
          { id: 166 }, { id: 175 }, { id: 184 },
          { id: 193 }, { id: 202 }, { id: 220 },
          { id: 229 }, { id: 238 }, { id: 247 },
          { id: 256 }, { id: 265 }, { id: 283 },
          { id: 292 }, { id: 457 }, { id: 475 },
          { id: 493 }, { id: 511 }, { id: 529 },
          { id: 547 }, { id: 565 }, { id: 583 }
      ];
      let index = lands.findIndex(x => parseInt(x.id) === (parseInt((id))-4999));
      if (index !== -1) {
          useStore.setState(state => state.missionsOwnGasStation = true);
      }
      // console.log(index)
      return;
  }
  
  export { 
    gasStationLand,
    getMissions,
    userMission,
    someMethodIThinkMightBeSlow,
    audio, 
    playAudio, 
    updateOwnedLandsProfit, 
    updateMoneyPerUser, 
    sendData, 
    updateProfile, 
    updateProfileBuyer,
    startAccount, 
    getCurrentDate, 
    updateProfileLands, 
    updateStockMarket,
    getOwnedLands, 
    getOwnedLandsSync,
    getSoldLands, 
    setLands, 
    time_ago,
    getRegions,
    getCountries,
    submitLandsOffer,
    getLandsOffers,
    cancelLandsOffer,
    acceptLandsOffer,
    collectLandsOffer,
    declineLandsOffer}
