import React, { useEffect, useState } from "react";
 import "./missions.css"
 import womenAgent from "../../assets/icons/women-agent.png"
import * as bg from '../../components/audio'
import useStore from "../Store";
 

const MissionPopup = (props) => {
  const [newNote, setnewNote] = useState(0);
  const [hide, sethide] = useState(true);
  const [count, setcount] = useState(0);
  let contents = props.content;
  const [missionNoteList, setmissionNoteList] = useState(0);
  function closeMenu(){
    sethide(false)
    setmissionNoteList(0)
  } 
  useEffect(() => {
      let newCount = parseInt(props.list)
      if (missionNoteList < newCount) {
        setmissionNoteList(newCount)
        setnewNote(newCount)
        if(useStore.getState().sound){
          playAudioNote();
        }
      }
      if (hide) {
        setmissionNoteList(0)
      }
  }, [hide, missionNoteList, props.list])
 
  function openMenu(){
    sethide(true)
   
  } 
  
  return (
    <div className="mission-popup-box">
      <img src={womenAgent} className="mission-icon" alt="mission" onClick={openMenu}/>
      <span className="missionNote-icon" alt="missionNote">{missionNoteList}</span>

      {
        ((hide) ?  (<>
        <div className="mission-box">
        <span className="mission-close-icon" onClick={closeMenu}>x</span>
        {props.header}
        <ul>
          {contents.map(x => x)}
        </ul>
        </div></>) : [])
      }
    </div>

  );
};
function playAudioNote(play = true, muted = true){
  
    if (play) {
      bg.notification.currentTime = 0
      bg.notification.volume = 0.05
      bg.notification.loop = false
      // bg.notification.muted = muted
      bg.notification.play()
    }else{
      bg.notification.pause();
    }
}
export default MissionPopup;