import { left } from "@popperjs/core";
import React, { useEffect, useState, useTransition } from "react";
import { updateOwnedLandsProfit, updateProfile } from "../../../core/Helpers";
import useStore from "../../../core/Store";

export default function LeftBoard(props){
    const money = useStore((state) => state.money)
    const lands = useStore((state) => state.landsOwned)
    const soldLands = useStore((state) => state.soldLands)
    const username = useStore.getState().username;
    const countries = useStore((state) => state.countriesList);
    const [showLands, setshowLands] = useState([]);
    const landsOwned = useStore((state) => state.landsOwned);
  const setSelect = (value) => useStore.setState((state) => { state.countrySelected = value})
  const selectedColor = useStore((state) => state.countrySelectedColor);
  const setSelectedColor = (value) => useStore.setState((state) => { state.countrySelectedColor = value})
  const selectedpopulation = useStore((state) => state.countrySelectedPopulation);
  const setSelectpopulation = (value) => useStore.setState((state) => { state.countrySelectedPopulation = value})
  const selectedId = useStore((state) => state.countrySelectedId);
  const setSelectId = (value) => useStore.setState((state) => { state.countrySelectedId = value})
  const landDress = useStore((state) => state.landDress);
  const setLandDress = (value) => useStore.setState((state) => { state.landDress = value})
  const setuserCash = (value) => useStore.setState((state) => state.money = value);
  let disabledCollect = false;
    //   useEffect(() => {
    //     setshowLands([])
    //     lands.forEach(element => {
    //         showLands.push(<li>{countries[parseInt(element.countryId)].name} - {element.landId}</li>)
    //       });
    //   }, [soldLands])
    //   lands.forEach(element => {
    //     showLands.push(<li>{countries[parseInt(element.countryId)].name} - {element.landId}</li>)
    //   });
    function visitPropOwned(e){
      e.stopPropagation();
      e.target.disabled= true;
      setSelect(e.target.getAttribute('name'));
      setSelectId(parseInt(e.target.getAttribute('countryid')))
      setSelectpopulation(parseInt(e.target.getAttribute('selectedpopulation')))
      setSelectedColor(e.target.getAttribute('selectedcolor'))
      setLandDress(e.target.getAttribute('landdress'))
      props.visitPropOwned();
    }

    function collectLandProfit(e){
      e.stopPropagation();
      e.target.disabled = true;
      e.target.visible = false;
      disabledCollect = true;
      let profitLandId = parseInt(e.target.getAttribute('landid'));
      let countryId =parseInt(e.target.getAttribute('countryid'));
      let profitCollect = parseFloat(e.target.getAttribute('profit'));
      let cashNow = (money + profitCollect);
      // collectedAgo = time_ago(Date.now());
      // profitCollect=0;
      setuserCash(cashNow);
      // setprofit(0);
       updateProfile();
      updateOwnedLandsProfit(profitLandId, countryId);
      setTimeout(() => {
        disabledCollect = false;
      }, 3000);
  }
  
    return(
        // <div className="board">  
        //     <div className="lands">
              <ul className="lands-owned">
              { (lands.length > 0 && countries.length > 0) ? lands.map((item, idx) => 
              <li key={idx*Math.random()*9999}> 
              ({idx+1}) <b>{countries[parseInt(item.countryId)].name} #{item.landId}</b>, 
              Cost: <b>{parseInt(item.pricebought).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</b>
              {(item.profit > 0 && !disabledCollect) && <button 
                  onClick={collectLandProfit} 
                  landid={item.landId} 
                  countryid={item.countryId} 
                  profit={parseFloat(item.profit)} >Collect {parseInt(item.profit).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$
              </button> }
              <button 
                      countryid={parseInt(item.countryId)} 
                      selectedpopulation={countries[parseInt(item.countryId)].population} 
                      landdress={countries[parseInt(item.countryId)].landDress}
                      selectedcolor={countries[parseInt(item.countryId)].color}
                      name={countries[parseInt(item.countryId)].name}
                      onClick={visitPropOwned}>Visit</button></li>): []}
              </ul>
          //   </div>
          // </div>
    )
}