import React, { useEffect, useState } from "react";
import useStore from "../../../core/Store";
import * as BS from "react-icons/bs";
import Popup from "../../../core/popup";
import { updateStockMarket } from "../../../core/Helpers";

export default function MarketStock(props){
    const stockMarket = useStore((state) => state.stockArray);
    const connectionError = useStore((state) => state.errorMsgConnection);
    const soldLands = useStore((state) => state.soldLands);
    const [error, setError] = useState('')
    const [hideShow, sethideShow] = useState(true)
    const [stockIterval, setstockIterval] = useState(0);
    
    useEffect(() => {
        const stockIterval = setInterval(() => {
            updateStockMarket();
        }, 20000);
        return () => {
            clearInterval(stockIterval);
        };
    }, []);

    function hideTab(e){
        sethideShow(!hideShow)
    }
    useEffect(() => {
            if (connectionError === '' && soldLands.length > 0) {
                setError([])
            }else{
                setError((<Popup content={ <>
                    <h2>Connecting to DDKits server...</h2>
                    <p>{connectionError}</p>
                </> } handleClose={null} />))
            }
    }, [connectionError, soldLands])
    return(<>
          <div hidden={hideShow} className="row stocks col" >
             { (stockMarket.length > 0) ? stockMarket.map((item, idx) => { 
                    let upOrDown = (item.avg > item.avgBefore) ? 'green': (item.avg < item.avgBefore) ? 'red': 'yellow';
                    let upOrDownSign = (item.avg > item.avgBefore) ? <BS.BsFillCaretUpFill />: (item.avg < item.avgBefore) ? <BS.BsFillCaretDownFill />: <BS.BsFillCaretRightFill />;
                    return <li className="col" key={idx*(-90000)} > {item.countryName}<p style={{  
                        color: upOrDown
                    } }> {upOrDownSign} {item.avg}</p> 
                    </li>
                }) : <BS.BsCloudDownload />}
                <button className="col-md-12" hidden={hideShow} onClick={hideTab}><BS.BsFillCaretUpFill />stocks<BS.BsFillCaretUpFill /></button>
                
          </div>
          <button className="col-md-12" hidden={!hideShow} onClick={hideTab}><BS.BsFillCaretDownFill />Stocks<BS.BsFillCaretDownFill /></button>
          {error}
          </>)
}