import React from "react";
 import "./popup.css"

const Popup = (props) => {
  return (
    <div className="popup-box">
      <div className="box">
        {(props.handleClose ? <span className="close-icon" onClick={props.handleClose}>x</span> : [])}
        {props.content}
      </div>
    </div>
  );
};
 
export default Popup;